<template>
  <p v-if="isVista" class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      &copy; حق کپی رایت توسط شرکت
      <b-link
        class="ml-25"
        href="https://vistaapp.ir"
        target="_blank"
      >ویستا </b-link>
      <span class="d-none d-sm-inline-block">محفوظ است</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <feather-icon
        icon="AlertCircleIcon"
        size="21"
        class="text-danger stroke-current"
      />
      &nbsp;طراحی شده توسط شرکت
      <b-link
        class="ml-25"
        href="https://vistaapp.ir"
        target="_blank"
      >ویستا </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  computed:{
    isVista(){
      return !location?.hostname?.includes('exirex')
    }
  }
}
</script>
